<template>
  <v-main>
    <introNavigation />
    <v-container fluid fill-height>
      <v-row align-center justify-center>
        <v-flex xs12 sm12 md12>
          <v-col cols="12" md="8" class="mx-auto">
            <p>{{ $t('register.notiRegistered.noti_1') }}</p>
            <p>{{ $t('register.notiRegistered.noti_2') }}</p>
            <p>{{ $t('register.notiRegistered.noti_3') }} 
            <span
              @click="isDisableBtnResend ? '' : resendEmailActive()"
              :class="isDisableBtnResend ? 'text-lowercase disable-class' : 'text-lowercase text-primary'"
              style="cursor: pointer;">{{ $t('register.resendActivationEmail') }}</span></p>
            <p>{{ $t('register.notiRegistered.noti_4') }} 
              <span
                @click="onReturn"
                class="text-lowercase text-primary"
                style="cursor: pointer;">{{ $t('common.login') }}</span></p>
          </v-col>
        </v-flex>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import IntroNavigation from "Components/IntroNavigation";
import { mapActions } from "vuex";
import functionUtils from "Utils/functionUtils";
import stringUtils from "Utils/stringUtils";
export default {
  components: {
    IntroNavigation,
  },
  data: () => ({
    isSuccess: false,
    isLoading: false,
    message: null,
    isDisableBtnResend: false
  }),
  created() {
    this.isLoading = true;
  },
  methods: {
    /**
     * Return
     */
    onReturn: function() {
      this.$router.push({
        name: "Login",
      });
    },
    resendEmailActive: function () {
      let query = this.$route.query
      let filter = {
        uid: query.uid,
        token: query.token,
        domainUrl: stringUtils.DOMAIN_BASE_PATH
      }
      this.isLoading = true
      this.isDisableBtnResend = true
      this.RESEND_ACTIVATE_ACCOUNT(filter).then(
        function () {
          this.isLoading = false
          this.$toast.open({
            message: this.$t("common.success"),
            type: "success",
            ...this.$toastConfig,
          });
          this.onDisableBtnResend()
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.$toast.clear()
            this.$toast.open({
              message: this.$t(errorText),
              type: "error",
              ...this.$toastConfig,
            });
          } else {
            this.$toast.clear()
            this.$toast.open({
              message: this.$t('login.somethingWentWrong'),
              type: "error",
              ...this.$toastConfig,
            });
          }
        }.bind(this)
      )
    },
    onDisableBtnResend: function () {
      this.isDisableBtnResend = true
      setTimeout(() => {
        this.isDisableBtnResend = false
      }, 60000)
    },
    ...mapActions(["RESEND_ACTIVATE_ACCOUNT"]),
  },
};
</script>

<style lang="scss" scoped>
.text-welcome {
  color: #FE6D6D !important;
  font-size: 24px;
  font-weight: 600;
}
.disable-class {
  color: #7d7d7d;
  cursor: no-drop;
}
</style>
